import { Tween } from '@tweenjs/tween.js';
import JoystickController from "joystick-controller";
import { createEffect } from "solid-js";
import { AudioListener, Clock, Color, DoubleSide, Group, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, PerspectiveCamera, PlaneGeometry, Scene, SphereGeometry, Vector2, Vector3, Vector3Like } from "three";
import { BatchedRenderer, Bezier, ColorOverLife, ConeEmitter, ConstantValue, EmitterMode, ForceOverLife, Gradient, IntervalValue, LimitSpeedOverLife, ParticleSystem, ParticleSystemParameters, PiecewiseBezier, RenderMode, SizeOverLife } from "three.quarks";
import { OrbitControls } from "three/examples/jsm/Addons.js";
import { lerp } from "three/src/math/MathUtils.js";
import { boss_id, crates, game_over, health_attr, is_mobile, range_attr, selected_sail, set_player_near_boss, set_pos, set_show_loader, set_show_ping, set_user_score, speed_attr, turn_speed_attr, user_score, using_chat } from "../../App";
import { SAIL_NAMES } from '../commons/customizations/sails';
import { LocalStorageKeys } from '../commons/enums/LocalStorageKeys';
import { cloneMesh } from '../commons/utils/cloneMesh';
import { createPlayerMesh, geomXWidth } from '../commons/utils/createPlayerMesh';
import { distanceBetween } from '../commons/utils/distanceBetween';
import { getBucketKey } from '../commons/utils/getBucketKey';
import { killAnim } from '../commons/utils/killAnim';
import { sailsChangeMat, sailsChangeMatReset } from '../commons/utils/sailsChangeMat';
import { PokiController } from '../commons/vendors/PokiController';
import { ATTRIBUTE_DEFAULTS, AUTO_SHOOT_VARIANCE_BEHIND, AUTO_SHOOT_VARIANCE_FORWARD, AUTO_SHOOT_VARIANCE_PERPENDICULAR_GREATER, AUTO_SHOOT_VARIANCE_PERPENDICULAR_LESSER, CAMERA_POS, FiringPositions, GAME_SIZE, MAX_POS, MIN_POS, SAIL_MAT_NAME } from "../constants";
import { AnalyticsController } from '../Controllers/AnalyticsController';
import { AudioController } from '../Controllers/AudioController';
import { CannonVolleyController } from '../Controllers/CannonVolleyController';
import { FoamController } from '../Controllers/FoamController';
import { ImpactController } from '../Controllers/ImpactController';
import { LocalStorageController } from '../Controllers/LocalStorageController';
import { NetController } from "../Controllers/NetController";
import { ShipInstanceController } from '../Controllers/ShipInstanceController';
import { SplashController } from '../Controllers/SplashController';
import { EventBus } from '../EventBus';
import { MESH_NAMES, mesh_repo, SHIP_SCALES } from "../ship_repo";
import { Battery } from "./Battery";
import { Island } from './Island';

const MATH_PI = Math.PI;

// const PLAYER_TURN_SPEED = 0.7;
const PLAYER_TURN_SPEED = 1.2;
// const PLAYER_TURN_SPEED = 1.4;
// const PLAYER_MOVEMENT_SPEED = 52.0;
// const PLAYER_MOVEMENT_SPEED = 1.4;
const PLAYER_MOVEMENT_SPEED = 1.7;

// const ROTATE_FOR = 0.1;
const ROTATE_FOR = 0.14;
// const ROTATE_HOR = 0.4;
const ROTATE_HOR = 0.052;

export const HIGHEST_SHIP_STAND = 0;
export const LOWEST_SHIP_SINK = -0.18;

const PLAYER_AXIS_VEC3 = new Vector3(0, 1, 0);

const RIGHT_DIR = new Vector2(0, 1);
const LEFT_DIR = new Vector2(0, -1);
const FORWARD_DIR = new Vector2(1, 0);
const BACK_DIR = new Vector2(-1, 0);

let nextPhysicsUpdate = 0;
let physicsDT = 1000 / 30;

export interface IHitPoint extends Vector3Like {
    hit?: boolean
}

export class Player {
    private clock = new Clock();

    mesh: Object3D
    meshInner: Object3D

    forward = new Vector3(-1, 0, 1);
    desiredForward = new Vector2();
    rot = 0;
    desiredAngle = 0;

    health = 0;
    maxHealth = ATTRIBUTE_DEFAULTS.HEALTH;

    // Player stats
    speed = 0;
    maxSpeed = ATTRIBUTE_DEFAULTS.SPEED;
    turnSpeed = ATTRIBUTE_DEFAULTS.TURN_SPEED;

    sailsMaterials: MeshStandardMaterial[] = [];

    range = ATTRIBUTE_DEFAULTS.RANGE;
    tweens: Tween<any>[];
    audioListener: AudioListener;
    sailsMesh: Mesh;
    nextFoamSpawn = 0;
    crateBin: Map<string, import("d:/Documents/__practical_projects/sunbacked/sunbacked-client/src/game/Templates/Crate").Crate> | undefined;
    showCTAIndicator: boolean;
    collectedCrates = 0;
    bossID: string | undefined;
    fps: number = -1;
    dirOnboarded = !!LocalStorageController.getItem(LocalStorageKeys.ONBOARDED);
    firingOnboarded = !!LocalStorageController.getItem(LocalStorageKeys.ONBOARDED);
    shotsFired = 0;
    movementsMade = 0;
    mobileJoystick: JoystickController | undefined;
    HAS_TOUCH = (window as any).HAS_TOUCH;

    get actualRange() {
        return this.range * SHIP_SCALES.BASIC_1;
    };

    frontGuns: Battery;
    // Left guns
    portGuns: Battery;
    // Right guns
    starboardGuns: Battery;
    rearGuns: Battery;

    // Water trails
    waterTrails: Mesh[] = [];

    pixelated = false;

    input = {
        rotateLeft: false,
        rotateRight: false,
        moveForward: false,

        fireForward: false,
        firePort: false,
        fireStarboard: false,
        fireBackward: false,
    }
    controlScheme = {
        TURN_LEFT: {
            primary: KeyboardKeys.ArrowLeft,
            secondary: '',
        },
        TURN_RIGHT: {
            primary: KeyboardKeys.ArrowRight,
            secondary: '',
        },
        MOVE_FORWARD: {
            primary: KeyboardKeys.ArrowUp,
            secondary: '',
        },
        FIRE_FORWARDS: {
            primary: KeyboardKeys.SPACEBAR,
            secondary: KeyboardKeys.W,
        },
        FIRE_BACKWARDS: {
            primary: KeyboardKeys.S,
            secondary: '',
        },
        FIRE_LEFT: {
            primary: KeyboardKeys.A,
            secondary: KeyboardKeys.Q,
        },
        FIRE_RIGHT: {
            primary: KeyboardKeys.D,
            secondary: KeyboardKeys.E,
        },
    }

    net: NetController;
    lastInput = performance.now();

    firingRange: Mesh;

    headerSection!: Group;
    healthBar!: Mesh;

    set score(newScore: number) {
        set_user_score(newScore);
    }

    get score() {
        return user_score();
    }

    xp = 0;

    alive = false;

    audioController!: AudioController;

    colliding: number[] = [];
    collidingSent = new Set<number>();

    currentBin: number;
    currentNeighbouringBins: string[] = [];
    prevNeighbouringBins: string[] = [];

    instanceController = ShipInstanceController.getInstance();
    instanceIX = 0;
    meshSubstitute: Object3D;

    //#region constructor
    constructor(public scene: Scene, public controls: OrbitControls, public camera: PerspectiveCamera, public batchSystem: BatchedRenderer) {
        this.lastInput = performance.now();
        this.collectedCrates = +(LocalStorageController.getItem(LocalStorageKeys.CRATES_COLLECTED) ?? 0)
        this.showCTAIndicator = this.collectedCrates < 3;

        this.audioListener = new AudioListener();

        const playerMeshes = createPlayerMesh();

        const mesh = this.mesh = playerMeshes.gameObj;
        const sailsMesh: Mesh = cloneMesh(mesh_repo[MESH_NAMES.BASIC_1_SAILS_DEFAULT].scene.clone() as any) as any;
        const arrowGuides: Mesh = cloneMesh(mesh_repo[MESH_NAMES.ARROW_GUIDES].scene.clone() as any) as any;
        const firingGuides: Mesh = cloneMesh(mesh_repo[MESH_NAMES.FIRING_GUIDES].scene.clone() as any) as any;
        let meshInner = this.meshInner = playerMeshes.gameObj.children[0];
        let meshSubstitute = this.meshSubstitute = playerMeshes.substitute;

        const guideContainer = new Group();

        if (!LocalStorageController.getItem(LocalStorageKeys.ONBOARDED)) {
            if (!is_mobile()) {
                guideContainer.add(arrowGuides, firingGuides);
                AnalyticsController.getInstance().addTag('IS_NEW', 'yes');
                AnalyticsController.getInstance().logIsNew();
    
                EventBus.on('arrows-used', () => {
                    if (++this.movementsMade >= 13) {
                        arrowGuides.removeFromParent();
                        this.dirOnboarded = true;
                        AnalyticsController.getInstance().logMovementUsed();
                    }
                });
                EventBus.on('fire-used', () => {
                    if (++this.shotsFired >= 25) {
                        firingGuides.removeFromParent();
                        this.firingOnboarded = true;
                        AnalyticsController.getInstance().logFiringUsed();
                    }
                });
            }
        } else {
            AnalyticsController.getInstance().logAlreadyOnboarded();
        }

        sailsMesh.name = 'SAILS';

        sailsMesh.rotation.y = Math.PI;
        sailsMesh.position.x = -0.9;
        sailsMesh.position.y = -1.7;
        // sailsMesh.position.z = 1.4;
        meshSubstitute.add(sailsMesh);

        mesh.add(guideContainer);

        arrowGuides.rotation.y = firingGuides.rotation.y = Math.PI;
        arrowGuides.position.y = firingGuides.position.y = 0.1;
        console.log('arrow guides:', arrowGuides);

        scene.add(meshSubstitute);
        scene.add(this.audioListener);

        console.log('pos:', meshInner.position, meshInner.rotation);
        console.log('mesh player:', mesh);

        sailsMesh.traverseVisible(child => {
            if (child instanceof Mesh && child?.material?.name == SAIL_MAT_NAME) {
                this.sailsMaterials.push(child.material);
                this.sailsMesh = child;
            }
        });

        this.frontGuns = new Battery(Math.PI * 0.5, -Math.PI * 0.5, 0.8, 1, this);
        this.portGuns = new Battery(Math.PI, 0, 0.5, 1, this);
        this.starboardGuns = new Battery(0, Math.PI, 0.5, 1, this);
        this.rearGuns = new Battery(-Math.PI * 0.5, Math.PI * 0.5, 0.8, 1, this);

        // #region Get player sails texture
        mesh.traverseVisible(child => {
            console.log('sail');
            if (child instanceof Mesh && child?.material?.name == SAIL_MAT_NAME) {
                console.log('sail found');
                // child.material = new MeshStandardMaterial({
                //     map: new TextureLoader().load('textures/sails/Layer_02.png')
                // })
                this.sailsMaterials.push(child.material);
            }
        });

        createEffect(() => {
            if (!sailsChangeMat(selected_sail() as SAIL_NAMES, this.sailsMesh)) {
                sailsChangeMatReset(this.sailsMesh);
            }
        });

        createEffect(() => {
            if (game_over()) {
                this.lastInput = performance.now();
            }
        });

        createEffect(() => {
            this.maxHealth = ATTRIBUTE_DEFAULTS.HEALTH + health_attr();
        });
        createEffect(() => {
            this.maxSpeed = ATTRIBUTE_DEFAULTS.SPEED + speed_attr();
        });
        createEffect(() => {
            this.turnSpeed = ATTRIBUTE_DEFAULTS.TURN_SPEED + turn_speed_attr();
        });
        createEffect(() => {
            this.range = ATTRIBUTE_DEFAULTS.RANGE + range_attr();
        });

        // @ts-ignore
        // meshInner.material.visible = false;

        this.frontGuns.scene = scene;
        this.portGuns.scene = scene;
        this.starboardGuns.scene = scene;
        this.rearGuns.scene = scene;

        // this.sailsMaterial.forEach(mat => {
        //     // mat.color = new Color(0xff0000)
        //     mat.map = new TextureLoader().load('textures/sky/sky1.jpg', texture => {
        //         texture.wrapS = texture.wrapT = 1001;
        //     });
        //     mat.needsUpdate = true;
        // });
        //#region Get guns for gun battery

        this.createHealthBar();

        this.setupParticleEmitters();

        camera.position.copy(mesh.position).add(CAMERA_POS);
        controls.target = mesh?.position;

        controls.update();

        scene.add(mesh);

        this.setupEventListeners();

        createEffect(() => {
            const playerID = this.net.id;

            if (this.bossID !== playerID && typeof boss_id() == 'string' && boss_id() == playerID) {
                AudioController.instance?.playChampionDeclaration();
            }

            this.bossID = boss_id();
        });
    }

    //#region createHealthBar
    createHealthBar() {
        const width = 1.4;
        const height = 0.1;

        const headerSection = this.headerSection = new Group();

        const barBase = new Mesh(new PlaneGeometry(width, height), new MeshBasicMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.34,
            side: DoubleSide
        }));
        const bar = this.healthBar = new Mesh(new PlaneGeometry(width, height), new MeshBasicMaterial({
            color: 0x5cb551,
            side: DoubleSide
        }));

        // barBase.scale.setScalar(1);
        // (bar.material as MeshBasicMaterial).color = new Color();
        bar.position.z = 0.01;

        // barBase.add(bar)
        headerSection.add(barBase);
        headerSection.add(bar);

        headerSection.position.copy(this.mesh.position);
        headerSection.position.y = 1;

        headerSection.lookAt(CAMERA_POS);

        this.scene.add(headerSection);
    }

    //#region Set Sails
    setSail = () => {
        if (!sailsChangeMat(selected_sail() as SAIL_NAMES, this.sailsMesh)) {
            sailsChangeMatReset(this.sailsMesh);
        }
    }

    //#region Update
    update(dt: number, now: number) {
        if (this.health <= 0) {
            return;
        }

        // if (now - this.lastInput >= TIMEOUT_TIME) {
        //     this.net.disconnect();
        // }

        const dtRatio = dt / 1000;

        const {

            camera,
            audioListener,

            input,
            forward,
            rot,
            desiredAngle,

            mesh,
            meshInner,
            meshSubstitute,

            health,
            maxHealth,
            maxSpeed,
            turnSpeed,

            clock,

            frontGuns,
            portGuns,
            starboardGuns,
            rearGuns,

            headerSection,
            healthBar,

            net,
            colliding,
            collidingSent,

            dirOnboarded,
        } = this;

        const {
            position,
        } = mesh;

        let rotatingHorAug = 0;
        let rotatingForAug = 0;

        //#region Mobile rotation and autoshoot
        if (is_mobile()) {
            // Reset inputs
            input.fireForward = input.fireBackward = input.firePort = input.fireStarboard = false;

            const desiredVec = this.desiredForward.set(Math.cos(desiredAngle), Math.sin(desiredAngle));
            const rotVec = forward.setX(Math.cos(rot)).setZ(Math.sin(rot));

            this.rot = Math.atan2(
                lerp(rotVec.z, desiredVec.y, dtRatio * turnSpeed),
                lerp(rotVec.x, desiredVec.x, dtRatio * turnSpeed),
            );
            // lerp(rot, desiredAngle, dtRatio)

            const dirVec = forward.setX(Math.cos(this.rot)).setZ(Math.sin(this.rot));

            // Auto shoot
            const range = this.actualRange;

            const targets = new Array(net.entities.size);

            const entities = net.entities.values();

            for (let entity of entities) {
                // If within range
                if (distanceBetween(entity.x, entity.y, position.x, position.z) <= range) {
                    // Get the dot product between the direction vector and the 
                    const dot = entity.mesh.position
                        .clone()
                        .sub(position)
                        .normalize()
                        .dot(dirVec);

                    // Math.random() < 0.34 && console.log('dot:', dot);

                    if (dot >= AUTO_SHOOT_VARIANCE_FORWARD) {
                        // Shoot forwards
                        input.fireForward = true;
                    } else if (dot <= AUTO_SHOOT_VARIANCE_BEHIND) {
                        // Shoot backwards
                        input.fireBackward = true;
                    } else if (dot >= AUTO_SHOOT_VARIANCE_PERPENDICULAR_LESSER && dot <= AUTO_SHOOT_VARIANCE_PERPENDICULAR_GREATER) {
                        input.firePort = input.fireStarboard = true;
                    }
                }
            }
        }

        !is_mobile() && forward.setX(Math.cos(this.rot)).setZ(Math.sin(this.rot));

        //#region Movement
        if (input.rotateLeft) {
            if (!dirOnboarded) {
                EventBus.emit('arrows-used');
            }
            forward.applyAxisAngle(PLAYER_AXIS_VEC3, turnSpeed * dtRatio);
            rotatingHorAug += ROTATE_HOR * MATH_PI;
        }
        if (input.rotateRight) {
            if (!dirOnboarded) {
                EventBus.emit('arrows-used');
            }
            forward.applyAxisAngle(PLAYER_AXIS_VEC3, -turnSpeed * dtRatio);
            rotatingHorAug += -ROTATE_HOR * MATH_PI;
        }
        let speed = 0;
        if (input.moveForward) {
            if (!dirOnboarded) {
                EventBus.emit('arrows-used');
            }
            speed = this.speed += lerp(0, maxSpeed, Math.max(30 / 1000, dtRatio));
            rotatingForAug = -ROTATE_FOR;
        } else {
            speed = this.speed = lerp(this.speed, 0, dtRatio);
        }

        if (speed > maxSpeed) {
            this.speed = speed = maxSpeed;
        } else if (speed < 0) {
            this.speed = speed = 0;
        }

        const newRot = this.rot = Math.atan2(forward.z, forward.x);

        const oldPos = position.clone();

        const xChange = Math.cos(newRot) * dtRatio * speed;
        const zChange = Math.sin(newRot) * dtRatio * speed;

        position.x += xChange;
        position.z += zChange;

        const healthPercent = health / maxHealth;

        //#region Health bar scaling
        healthBar.scale.set(healthPercent, 1, 1);

        mesh.rotation.y = -newRot;
        meshSubstitute.rotation.y = mesh.rotation.y + Math.PI;

        //#region Bobbing in water
        meshSubstitute.rotation.x = lerp(
            meshSubstitute.rotation.x,
            (0.052 * Math.sin(MATH_PI * clock.getElapsedTime() / 2.5) + rotatingHorAug),
            dtRatio
        );
        meshSubstitute.rotation.z = lerp(
            meshSubstitute.rotation.z,
            0.034 * Math.sin(MATH_PI * clock.getElapsedTime() / 5.2) + rotatingForAug,
            dtRatio
        );

        let binName = getBucketKey(position.x, position.z);

        //#region Collisions with other entities static or dynamic

        if (performance.now() >= nextPhysicsUpdate) {
            nextPhysicsUpdate = performance.now() + physicsDT;

            const islands = net.islandsBins.get(binName) || [];

            const entities = Array.from([...net.entities.values(), ...islands]);


            entities.forEach(entity => {
                if (!entity.health) return;

                const vec2 = new Vector2(entity.x - position.x, entity.y - position.z);

                const dist = vec2.length();

                const radius = (entity as Island).isIsland ? 1.9 : geomXWidth * SHIP_SCALES.BASIC_1;

                if (dist < radius) {
                    const newLength = radius - dist;

                    vec2.setLength(newLength);

                    vec2.negate();

                    if ((entity as Island).isIsland) {
                        // Player and island are colliding

                        position.x += lerp(0, vec2.x, physicsDT / 1000);
                        position.z += lerp(0, vec2.y, physicsDT / 1000);
                    } else {
                        // Player and entity are colliding
                        position.x += vec2.x;
                        position.z += vec2.y;
                    }
                }
            });
        }

        position.clamp(MIN_POS, MAX_POS);
        const posDiff = position.clone().sub(oldPos);

        camera.position.add(posDiff);
        audioListener.position.copy(position);

        meshSubstitute.position.copy(position);
        meshSubstitute.position.y = 0.2418668;
        // meshSubstitute.rotation.copy(mesh.rotation);
        // meshSubstitute.rotation.y = Math.atan2(forward.z, forward.x);

        this.instanceController.updateMesh(this.instanceIX, meshSubstitute);

        //#region Check if we are colliding with any crates
        binName = getBucketKey(position.x, position.z);

        if (this.currentBin != binName) {
            // const groups = net.groups;

            // groups.get(this.currentBin)?.removeFromParent();
            // const group = groups.get(binName);
            // group && this.scene.add(group);

            // console.log('new group:', group);

            this.crateBin = net.crateBins.get(binName);
            this.currentBin = binName;
        }

        const crateBin = this.crateBin;

        if (crateBin) {
            const {
                x,
                z,
            } = position;

            const processCrate = (crate: any) => {
                const { mesh, ix } = crate;
                let meshPos = mesh.position;

                const dist = distanceBetween(x, z, meshPos.x, meshPos.z);
                if (dist <= 0.7) {
                    if (!collidingSent.has(ix) && !colliding.includes(ix)) {
                        colliding.push(ix);
                        console.log('binName:', binName);
                    }
                }

                return [dist, crate] as const;
            };

            if (this.showCTAIndicator) {
                if (this.collectedCrates >= 3) {
                    this.showCTAIndicator = false;
                } else {
                    const cratesSorted = Array.from(crateBin.values()).map(processCrate).sort((a, b) => a[0] - b[0]);

                    if (cratesSorted.length) {
                        const nearestCrate = cratesSorted[0][1];

                        // (nearestCrate.iMesh.material as MeshStandardMaterial).color = new Color(0xff0000);

                        //#region Add indicator on nearest crate
                        if (nearestCrate) {
                            const textMesh = mesh_repo[MESH_NAMES.CTA_TEXT].scene;
                            textMesh.scale.setScalar(0.34);
                            textMesh.position.copy(nearestCrate.mesh.position).setY(-0.25);

                            textMesh.rotation.y = Math.PI;

                            this.scene.add(textMesh);
                            nearestCrate.cta = textMesh;
                        }
                    }
                }
            } else {
                crateBin.forEach(processCrate);
            }

            colliding.length && console.log('colliding:', colliding.concat([]));
        }

        const bossID = boss_id();

        if (bossID) {
            // Check if the currently selected top player is within range (34 units)
            const boss = net.entities.get(bossID);

            if (boss && boss.health) {
                const bossPos = boss.mesh.position;

                if (distanceBetween(position.x, position.y, bossPos.x, bossPos.y) <= 25) {
                    set_player_near_boss(true);
                } else {
                    set_player_near_boss(false);
                }
            } else {
                set_player_near_boss(false);
            }
        } else {
            set_player_near_boss(false)
        }

        headerSection.position.copy(position);
        headerSection.position.y = 1;

        if (this.nextFoamSpawn < now) {
            this.nextFoamSpawn = now + 70;
            FoamController.triggerFoam(position.x, position.z);
        }

        // #region Fire guns
        if (input.fireForward && now >= frontGuns.nextFire) {
            frontGuns.nextFire = now + frontGuns.reloadTime;

            AudioController.getInstance().playNoGunFire();
            this.attackDirection(FORWARD_DIR, frontGuns, FiringSide.FORWARD);
        } else if (input.fireForward) {
            !is_mobile() && AudioController.getInstance().playNoGunFire();
        }
        if (input.firePort && now >= portGuns.nextFire) {
            portGuns.nextFire = now + portGuns.reloadTime;

            AudioController.getInstance().playNoGunFire();
            this.attackDirection(LEFT_DIR, portGuns, FiringSide.PORT);
        } else if (input.firePort) {
            !is_mobile() && AudioController.getInstance().playNoGunFire();
        }
        if (input.fireStarboard && now >= starboardGuns.nextFire) {
            starboardGuns.nextFire = now + starboardGuns.reloadTime;

            AudioController.getInstance().playNoGunFire();
            this.attackDirection(RIGHT_DIR, starboardGuns, FiringSide.STARBOARD);
        } else if (input.fireStarboard) {
            !is_mobile() && AudioController.getInstance().playNoGunFire();
        }
        if (input.fireBackward && now >= rearGuns.nextFire) {
            rearGuns.nextFire = now + rearGuns.reloadTime;

            AudioController.getInstance().playNoGunFire();
            this.attackDirection(BACK_DIR, rearGuns, FiringSide.BACKWARD);
        } else if (input.fireBackward) {
            !is_mobile() && AudioController.getInstance().playNoGunFire();
        }

        set_pos({
            x: position.x / GAME_SIZE,
            y: position.z / GAME_SIZE,
        });

        // this.resetInputs();
    }
    //#endregion

    //#region Attack Direction
    attackDirection(direction: Vector2, battery: Battery, side: FiringSide) {
        try {
            const {
                mesh,
                forward,
                net,
            } = this;

            !this.firingOnboarded && EventBus.emit('fire-used');

            const pPos = mesh.position;

            const analyticsControllerInstance = AnalyticsController.getInstance()
            analyticsControllerInstance.logShoot(pPos.x, pPos.y, side);

            const cratesList = crates();

            analyticsControllerInstance.logShootDistFromChest(Math.min(...Object.keys(cratesList).map(key => {
                const crate = cratesList[key];
                return distanceBetween(pPos.x, pPos.y, crate.x * GAME_SIZE, crate.y * GAME_SIZE);
            })));

            // const playerVec3 = new Vector3(mesh.position.x, 0, mesh.position.z);
            let gunForwardVec2 = new Vector2(forward.x, forward.z)
                .rotateAround(new Vector2(0, 0), direction.angle())
                .normalize();
            // let gunForwardVec3 = forward.clone().normalize();
            // const forwardVec3 = forward.clone().normalize();
            const forwardVec2 = new Vector2(forward.x, forward.z).normalize();
            const range = this.actualRange;

            console.log('forward:', forwardVec2);
            console.log('gun dir:', gunForwardVec2);

            const gunForwardVec3 = new Vector3(gunForwardVec2.x, 0, gunForwardVec2.y);

            // this.firingRange.rotation.y = -Math.atan2(gunForwardVec2.y, gunForwardVec2.x);
            // this.firingRange.geometry = new CylinderGeometry(this.actualRange, this.actualRange, 2, 24, 24, false, Math.PI * startRange * 0.5, Math.PI * (Math.abs(endRange - startRange)));

            // window.g = {
            //     x: gunForwardVec2.x,
            //     y: gunForwardVec2.y,
            //     // z: playerDirVec3Normalized.z,
            //     a: this.firingDirIndicator.rotation.y
            // }

            let misses: (IHitPoint)[] = [];

            const availableTargets = Array.from(net.entities).map(([_, entity]) => {
                if (entity.health > 0) {
                    return entity.meshInner;
                }
            }).filter(entity => !!entity) as Mesh[];

            // console.log('available:', availableTargets);

            const hitTargets = battery.guns.map((gun, ix): string | undefined => {
                const raycaster = battery.raycasters[ix];

                const gunPos = gun.getWorldPosition(new Vector3());
                gunPos.y = 0.01;

                // console.log('length:', gunPos.clone().sub().setY(0.0).length());

                raycaster.set(gunPos, gunForwardVec3);
                raycaster.far = range;
                // raycaster.ray.origin

                const hits = raycaster.intersectObjects(availableTargets, true);

                console.log('hits:', hits);

                const hit = hits?.[0];
                const target = hit?.object.parent;

                const start = gunPos;
                const end = gunForwardVec3.clone().normalize().multiplyScalar(range);

                const v1 = start.clone().add(end).sub(mesh.position).length();

                // @ts-ignore
                window.mag = (window.mag || 0) < v1 ? v1 : window.mag;
                // @ts-ignore
                window.range = range;

                //#region Line representing the ray
                // const points = [
                //     start,
                //     start.clone().add(end),
                // ];

                // const lineGeom = new BufferGeometry().setFromPoints(points);
                // const lineMat = new LineBasicMaterial({
                //     color: 0xffff00,
                //     linewidth: 1
                // });
                // const lineMesh = new Line(lineGeom, lineMat);

                // this.scene.add(lineMesh);
                //#endregion

                let hitPoint: IHitPoint;

                if (hit) {
                    const {
                        x,
                        y,
                        z,
                    } = hit.point;

                    hitPoint = {
                        x,
                        y,
                        z,
                        hit: true,
                    };

                    misses.push(hitPoint);

                    setTimeout(() => {
                        SplashController.triggerSplash(x, z);
                        ImpactController.triggerImpact(x, y, z);
                    }, battery.firingPattern[ix]/*  + net.ping */)
                } else {
                    const splashLocation = start.clone().add(end);

                    hitPoint = {
                        x: splashLocation.x,
                        y: 0.1,
                        z: splashLocation.z,
                    };

                    misses.push(hitPoint);
                    console.log('missed:', splashLocation.x, splashLocation.z);
                    setTimeout(() => {
                        SplashController.triggerSplash(splashLocation.x, splashLocation.z);
                        // ImpactController.triggerImpact(splashLocation.x,splashLocation.y, splashLocation.z);
                    }, battery.firingPattern[ix] + net.ping)
                }
                CannonVolleyController.triggerCannonAnim(battery.guns[ix].getWorldPosition(new Vector3()), hitPoint, battery.firingPattern[ix] + net.ping);

                console.log('target:', target)
                // @ts-ignore
                if (target?.parentEntity?.id) {
                    // @ts-ignore
                    return target?.parentEntity?.id;
                }
            });

            AudioController.getInstance().playGunFireTail(mesh);
            battery.fire();

            net.sendMessage('DAMAGE', {
                // x: mesh.position.x,
                // y: mesh.position.z,
                // dx: forward.x,
                // dy: forward.z,
                t: side,
                hs: hitTargets.filter(target => !!target),
                mss: misses,
            });
        } catch (err) {
            console.error(err)
        }

    }

    //#region reset
    reset({ x, y, dx, dy, sc }: { [key: string]: number | undefined }) {
        set_show_loader(false);

        this.mesh.visible = true;
        this.health = this.maxHealth;
        this.mesh.position.set(x || 0, 0, y || 0);
        // this.meshInner.position.set(0.804959774017334, 1, 0);
        // this.meshInner.rotation.set(0, 0, 0);
        console.log('reset:', dx, dy);
        let newDX: number = dx || 1;

        if (dx == 0 && dy == 0) {
            newDX = (Math.random() > 0.5 ? -1 : 1) * Math.max(0.1, Math.random());
        }

        // const forwardVec2 = new Vector2(newDX, dy).setLength(1);

        const radian = this.rot = this.desiredAngle = Math.atan2(dy || 0, newDX);

        this.forward.setX(Math.cos(radian)).setY(Math.sin(radian));
        console.log('reset post clamped:', this.forward.x, this.forward.z);
        this.camera.position.set(x || 0, 0, y || 0).add(CAMERA_POS);
        this.controls.update();

        this.alive = true;

        this.score = sc || 0;
        this.healthBar.scale.set(1, 1, 1);
        this.headerSection.visible = true;
        this.meshSubstitute.rotation.set(0, 0, 0);

        this.setupVirtualController();
        
        // const t = getCachedText(TextType.CHEST);

        // if (t) {
        //     t.
        // }

        // SplashController.triggerSplash(x || 0, y || 0);
        // const m = new Mesh(new BoxGeometry(0.1, 0.1, 0.1), new MeshStandardMaterial());
        // m.position.copy(new Vector3(x, 0, y));

        // this.scene.add(m);

        // const c = new Mesh(new CircleGeometry(10));
        // c.position.set(x, 0.1, y);
        // c.rotation.x = -Math.PI * 0.5;
        // this.scene.add(c);
    }

    //#region kill
    kill() {
        console.log('Player died');

        killAnim(this);

        this.alive = this.mesh.visible = false;
        this.resetInputs();

        this.healthBar.scale.set(0, 1, 1);
        this.headerSection.visible = false;

        this?.mobileJoystick?.destroy?.();
        this.mobileJoystick = undefined;

        // CrazyGamesController.getInstance().stopGameplaySession();
        PokiController.getInstance().stopGameplaySession();
        AnalyticsController.getInstance().logGameplayEnd();
    }

    //#region setupParticleEmitters
    setupParticleEmitters() {
        const {
            batchSystem,
            mesh,
            frontGuns,
            portGuns,
            starboardGuns,
            rearGuns,
        } = this;

        const geo = new SphereGeometry(0.1, 8, 8);
        const mat = new MeshBasicMaterial({
            color: new Color(0x000),
        });

        const particlesConfig = {
            duration: 0.01,
            looping: false,
            instancingGeometry: geo,
            worldSpace: true,

            shape: new ConeEmitter({
                radius: 0.01,
                arc: 6.2831,
                // thickness: 0.10,
                // thickness: 10,
                // speed: new ConstantValue(20),
                spread: 34,
                angle: 0.122173,
                // angle: Math.PI*0.25,
                mode: EmitterMode.Burst,
            }),

            // startLife: new IntervalValue(0.0, 0.5),
            // endLife: new IntervalValue(0.43, 0.5),
            startSpeed: new IntervalValue(0.1, 5),
            endSpeed: new IntervalValue(7, 10),
            startSize: new IntervalValue(1.2, 1.2),
            endSize: new IntervalValue(1.2, 1.2),
            // startColor: new ConstantColor(new Vector4(1, 1, 1, 1)),
            emissionOverTime: new ConstantValue(1510),

            material: mat,
            renderMode: RenderMode.Mesh,
            renderOrder: 25,
            startTileIndex: new ConstantValue(0),
            uTileCount: 2,
            vTileCount: 2,

            behaviors: [
                new ForceOverLife(new ConstantValue(0), new ConstantValue(0.052), new ConstantValue(0)),
                new ColorOverLife(new Gradient([
                    [new Vector3(1, 1, 1), 0],
                    [new Vector3(0.25, 0.25, 0.25), 1],
                ],
                    [
                        [1, 0],
                        [0, 1]
                    ]
                )),
                new LimitSpeedOverLife(new PiecewiseBezier([[new Bezier(0, 0, 0, 0), 0]]), 0.25),
                new SizeOverLife(new PiecewiseBezier([[new Bezier(1, 1, 0, 0), 0]])),
            ],
        } as ParticleSystemParameters;

        const allGuns = [
            [frontGuns, FiringSide.FORWARD],
            [portGuns, FiringSide.PORT],
            [starboardGuns, FiringSide.STARBOARD],
            [rearGuns, FiringSide.BACKWARD],
        ] as const;

        allGuns.forEach(([battery, SIDE], ix) => {
            const {
                guns,
                direction,
                emitterSystems,
            } = battery;

            FiringPositions[SIDE].forEach((gun, gunIX) => {
                // Create particle system based on your configuration
                [particlesConfig].forEach((config, ix2) => {
                    const system = new ParticleSystem(config);

                    const gunPlacement = new Object3D();
                    gunPlacement.position.copy(new Vector3().fromArray(gun));
                    battery.addGun(gunPlacement);

                    this.meshInner.add(gunPlacement);

                    system.emitter.name = `muzzle-type:${ix}-gun${gunIX + 1}-${ix2}`;
                    system.emitter.position.copy(gunPlacement.position);
                    system.emitter.rotation.y = direction;

                    emitterSystems.push(system);
                    batchSystem.addSystem(system);
                    this.meshInner?.add(system.emitter);

                    system.endEmit();
                });
            });
        });
    }

    //#region resetInputs
    resetInputs = () => {
        console.log('reset inputs:');

        this.input.rotateLeft = false;
        this.input.rotateRight = false;
        this.input.moveForward = false;
        this.input.fireForward = false;
        this.input.fireBackward = false;
        this.input.firePort = false;
        this.input.fireStarboard = false;
    }

    //#region resetMeshInnerRotation
    resetMeshInnerRotation = () => {
        // this.meshInner.rotation.x = 0;
        // this.meshInner.rotation.z = 0;
    }

    destroy() {
        window.onkeydown = window.onkeyup = window.onauxclick = window.onscroll = null;
    }

    //#region setupEventListeners
    private setupEventListeners() {
        const { input, controlScheme } = this;

        if (is_mobile()) {
            this.setupVirtualController();
        }


        const updateInput = (ev: KeyboardEvent, isPressing: boolean) => {

            if (using_chat()) {
                this.lastInput = performance.now();
                return;
            } if (game_over()) {
                return;
            } else {
                ev.preventDefault();
            }

            if (!using_chat() && this.mesh.visible) {
                // this.lastInput = performance.now();
                switch (ev.key) {
                    case controlScheme.TURN_LEFT.primary:
                    case controlScheme.TURN_LEFT.secondary:
                        if (input.rotateLeft !== isPressing) {
                            input.rotateLeft = isPressing;
                        }
                        break;
                    case controlScheme.TURN_RIGHT.primary:
                    case controlScheme.TURN_RIGHT.secondary:
                        if (input.rotateRight !== isPressing) {
                            input.rotateRight = isPressing;
                        }
                        break;
                    case controlScheme.MOVE_FORWARD.primary:
                    case controlScheme.MOVE_FORWARD.secondary:
                        if (input.moveForward !== isPressing) {
                            input.moveForward = isPressing;
                        }
                        break;

                    // Firing controls
                    case controlScheme.FIRE_FORWARDS.primary:
                    case controlScheme.FIRE_FORWARDS.secondary:
                        if (input.fireForward !== isPressing) {
                            input.fireForward = isPressing;
                        }
                        break;
                    case controlScheme.FIRE_BACKWARDS.primary:
                    case controlScheme.FIRE_BACKWARDS.secondary:
                        if (input.fireBackward !== isPressing) {
                            input.fireBackward = isPressing;
                        }
                        break;
                    case controlScheme.FIRE_LEFT.primary:
                    case controlScheme.FIRE_LEFT.secondary:
                        if (input.firePort !== isPressing) {
                            input.firePort = isPressing;
                        }
                        break;
                    case controlScheme.FIRE_RIGHT.primary:
                    case controlScheme.FIRE_RIGHT.secondary:
                        if (input.fireStarboard !== isPressing) {
                            input.fireStarboard = isPressing;
                        }
                        break;
                    case 'p':
                        if (!isPressing) {
                            this.pixelated = !this.pixelated;
                        }
                        break;
                    case 'o':
                        if (!isPressing) {
                            set_show_ping(prev => !prev);
                        }
                        break;
                }
            }
        };

        window.onkeydown = (ev) => {
            this.lastInput = performance.now();
            updateInput(ev, true);
        };
        window.onkeyup = (ev) => {
            this.lastInput = performance.now();
            if (ev.key.toLowerCase() == 'enter') {
                console.log('enter pressed');

                const inputEle = document.getElementById('chat-input') as HTMLInputElement;

                if (!using_chat()) {
                    if (inputEle) {
                        inputEle.focus();
                    }
                } else {
                    if (ev.key.toLowerCase() == 'enter') {
                        ev.preventDefault();
                        if (inputEle.value) {
                            document.getElementById('chat-send-btn')?.click();
                        } else {
                            const canvas = document.querySelector('#game-container canvas') as HTMLCanvasElement;

                            if (canvas) {
                                // input.blur();
                                // canvas?.click?.();
                                canvas.focus();

                                console.log('target canvas:', canvas);
                            }
                        }
                    }
                }
            } else {
                updateInput(ev, false);
            }
        };
        window.onauxclick = () => {
            this.lastInput = performance.now();
            this.resetInputs();
        };
        window.onscroll = (ev) => {
            this.lastInput = performance.now();
            ev.preventDefault();
        }
    }

    setupVirtualController() {
        this?.mobileJoystick?.destroy?.();
        
        this.mobileJoystick = new JoystickController({
            // distortion: true,
            mouseClickButton: 'RIGHT',
            dynamicPosition: true,
        }, (data) => {
            this.lastInput = performance.now();

            const {
                angle,
                distance
            } = data;


            if (distance > 0) {
                this.desiredAngle = angle;
                this.input.moveForward = true;
            } else {
                this.input.moveForward = false;
                this.desiredAngle = this.rot;
            }
            // console.log('data:', data);
        });
    }
}

enum KeyboardKeys {
    W = 'w',
    A = 'a',
    S = 's',
    D = 'd',
    Q = 'q',
    E = 'e',
    SPACEBAR = ' ',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
}

export enum FiringSide {
    FORWARD,
    PORT,
    STARBOARD,
    BACKWARD,
}